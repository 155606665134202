import usdt from "./assets/usdt.png";
import bnb from "./assets/bnb.png";
import tokenImage from "./assets/token.png";

const info = {
  contractAddress: "0x7347566748720A4F5c46C8F0479E75A57e8fD81A",
  usdtAddress: "0x84b9B910527Ad5C03A9Ca831909E21e236EA7b06",
  tokenDecimal: 18,
  refPercent: 10,
  referral: false,
  tokens: [
    {
      name: "Tether USD",
      symbol: "USDT",
      image: usdt,
      baseToken: true,
      address: "0x84b9B910527Ad5C03A9Ca831909E21e236EA7b06",
    },
    {
      name: "Binance Coin",
      symbol: "BNB",
      image: bnb,
      native: true,
      address: "0x0000000000000000000000000000000000000000",
    },
  ],
  buyToken: {
    name: "VIOLETAI",
    symbol: "VIOLETAI",
    image: tokenImage,
  },
};

export default info;
